
import { Options, Vue } from 'vue-class-component';
import { AdminClient, InternalUserClient } from '@/services/Services';
import { LoginServices } from '@/services/LoginServices';
import * as OM from "@/Model";
import store from '@/store';
import InfoModal from '@/components/modals/infoModal.vue';

@Options({})
export default class Login extends Vue {

    languageCredential: OM.CultureBaseCredential = new OM.CultureBaseCredential();

    created() {
        var loggedUser = store.state.loggedUser;
        this.languageCredential.preferredCulture = loggedUser.preferredCulture;
    }

    login() {
        InternalUserClient.login(this.languageCredential)
        .then(x => {
            LoginServices.LoginCallback(x.token, x.refreshToken);
        })
        .catch(y => {
            this.$opModal.show(InfoModal, {
                text:this.$localizationService.getLocalizedValue("backoffice_Errore", "Errore"),
                subtitle: y.Message,
                deny: this.$localizationService.getLocalizedValue("backoffice_chiudi", "Chiudi"),
                denyCb: () => {
                    this.$opModal.closeLast();
                }
            })
        })
    }

    get disabled() {
        return !this.languageCredential.username || !this.languageCredential.password;
    }

}
